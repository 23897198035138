/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Itsmyhealth = {
    // All pages
    'common': {
      init: function() {
        /* ========================================================================
         *  Menu toggle
         * ======================================================================== */
        var toggleBtn = $('.toggle-menu');
        var menu = $('.nav-primary');

        toggleBtn.attr('aria-expanded', false).attr('aria-controls', 'nav-primary');
        menu.attr('aria-hidden', true);

        toggleBtn.on('click', function() {
          var state = $(this).attr('aria-expanded') === 'false' ? true : false;
          $(this).attr('aria-expanded', state);
          menu.attr('aria-hidden', !state);
        });


        /* ========================================================================
         *  Zoho chat
         * ======================================================================== */
        var triggerBtn = '<button class="open-chat" />';
        $('.chat-trigger').wrapInner(triggerBtn);

        $('.open-chat').on('click', function() {
          $zoho.salesiq.floatwindow.visible('show');
          $('.zls-sptwndw').focus();
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {

        /* ========================================================================
         *  People like us content overlay
         * ======================================================================== */

        // The div to place content
        var overlay = $('#person-overlay');

        // Each of the 'person' boxes
        $('.feature-people__individual').each(function(){
          // grab content from data attributes
          var $this = $(this),
              title = $this.children('.feature-people__bd').data('expanded-title'),
              content = $this.children('.feature-people__bd').data('expanded-content'),
              image = $this.children('.feature-people__bd').data('expanded-image');

          // Wrap in a button for the trigger
          $this.children('.feature-people__bd').wrapAll('<button aria-haspopup="true">');
          var button = $this.children('button');

          // Open overlay and pull in content
          button.on('click', function(){
            var pos = $(this).position();

            if(pos.left > 10 && pos.left <= 50) {
              overlay.css({'left': pos.left, 'right': '50%'});
            } else {
              overlay.css({'left': '', 'right': ''});
            }

            overlay.find('.feature-people__expanded').css('background-image', 'url(' + image + ')');
            overlay
              .find('.person-overlay__inner')
              .html('<p>' + content + '</p><p class="feature-people__title">' + title + '</p>');
            overlay.addClass('expanded');
          });
        });

        // on click of the overlay close button.
        $('.person-overlay__btn').on('click', function(){
          overlay.removeClass('expanded');
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // FAQ's
    'page_template_faq_template': {
      init: function() {

        /* ========================================================================
         *  FAQ's
         * ======================================================================== */

        // Function to handl showing/closing all the FAQ's
        function toggleAll(direction) {
          $('.faq__question').each(function() {
            var $this = $(this);
            var state = direction === 'expand' ? true : false;

            $this.next().attr('aria-hidden', !state);
            $this.children('button').attr('aria-expanded', state);
          });
        }

        // Append the buttons for showing/closing all
        var expandBtn = '<button id="faq-expand">Expand All</button>';
        var collapseBtn = '<button id="faq-collapse">Collapse All</button>';
        $('.faqs').before('<div class="faq-btns">' + expandBtn + collapseBtn + '</div>');

        $('#faq-expand').on('click', function() {
          toggleAll('expand');
        });

        $('#faq-collapse').on('click', function() {
          toggleAll('collapse');
        });

        // Wrap each faq with the required functionality for collapsing/showing
        $('.faq__question').each(function() {

          var $this = $(this);
          var id = 'collapsible-' + $this.index();

          $this.nextUntil('h3').wrapAll('<div class="faq__answer" id="'+ id +'" aria-hidden="true">');
          var panel = $this.next();

          $this.wrapInner('<button aria-expanded="false" aria-controls="'+ id +'">');
          var button = $this.children('button');

          button.on('click', function() {
            var state = $(this).attr('aria-expanded') === 'false' ? true : false;
            $(this).attr('aria-expanded', state);
            panel.attr('aria-hidden', !state);
          });

        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Itsmyhealth;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
